import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithPhoneNumber,
  onAuthStateChanged,
  RecaptchaVerifier,
  signOut,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB2fTBpo2KofMitbeNiJflLN9Q5xNImJjI",
  authDomain: "bowandsternind.firebaseapp.com",
  projectId: "bowandsternind",
  storageBucket: "bowandsternind.appspot.com",
  messagingSenderId: "173748417343",
  appId: "1:173748417343:web:1bb9ec4b4d3795a433998a",
};

const app = initializeApp(firebaseConfig);
const Auth = getAuth(app);

const googleLogin = async () => {
  const provider = new GoogleAuthProvider();
  try {
    await signInWithPopup(Auth, provider);
    window.location.replace("/");
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const email = error.customData.email;
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.log(errorCode, errorMessage, email, credential);
  }
};

const getOtp = async (
  phoneNumber: string,
  element: HTMLElement,
  callback: () => void,
  errorCallback: () => void
) => {
  try {
    const auth = getAuth();
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      element,
      {
        size: "invisible",
        callback,
      },
      auth
    );

    const appVerifier = (window as any).recaptchaVerifier;
    const confirmationResult = await signInWithPhoneNumber(
      auth,
      phoneNumber,
      appVerifier
    );
    (window as any).confirmationResult = confirmationResult;
  } catch (error: any) {
    console.log(error, error.code, error.message);
  }
};

const submitOtp = async (code: string) => {
  await (window as any).confirmationResult.confirm(code);
};

const resetRecaptcha = () =>
  (window as any).recaptchaVerifier.render().then(function (widgetId: any) {
    (window as any).grecaptcha.reset(widgetId);
  });

const phoneLogin = {
  getOtp,
  submitOtp,
  resetRecaptcha,
};

const isLoggedIn = async () => {
  return new Promise((resolve) => {
    onAuthStateChanged(Auth, (user) => {
      if (user) {
        resolve(user.providerData[0]);
      } else {
        resolve(null);
      }
    });
  });
};

const logout = async () => {
  const auth = getAuth();
  await signOut(auth);
  window.location.reload();
};

export { isLoggedIn, googleLogin, phoneLogin, logout };
