import { createRef, useState } from "react";
import { phoneLogin, googleLogin } from "../../stores/user";
import "./index.scss";

function Login({ close }: { close: () => void }) {
  const [number, setNumber] = useState("");
  const [otp, setOTP] = useState();
  const [showOTP, setShowOTP] = useState(false);
  const loginButton: any = createRef();

  const generateOTP = async () => {
    if (!number) return;
    await phoneLogin.getOtp(
      "+91" + number.replace(/\s/g, ""),
      loginButton.current,
      () => setShowOTP(true),
      () => window.location.reload()
    );
  };

  const phoneNumberLogin = async () => {
    try {
      if (!otp) return;
      await phoneLogin.submitOtp(otp);
      window.location.replace("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`Login`}>
      <div className="blur" onClick={close}></div>
      <div className="wrapper">
        <h2 className="ui header">
          <i className="user circle outline icon"></i>
          <div className="content">
            Welcome back
            <div className="sub header">Login with your preferences</div>
          </div>
          <i className="inverted circular close icon" onClick={close}></i>
        </h2>
        <button className="ui primary basic button fluid" onClick={googleLogin}>
          <i className="google icon"></i>
          Google Login
        </button>
        {/* <button className="ui primary basic button fluid">
          <i className="apple icon"></i>
          Apple Login
        </button> */}
        <div className="ui horizontal divider fitted">OR</div>

        {!showOTP && (
          <>
            <div className="ui input fluid">
              <input
                type="number"
                max="9999999999"
                pattern="/^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$/"
                placeholder="Enter Phone Number"
                value={number}
                onInput={({ target: { value } }: any) =>
                  value.toString().length === 11 ? false : setNumber(value)
                }
              />
            </div>
            <button
              ref={loginButton}
              className="ui primary button fluid"
              id="sign-in-button"
              onClick={generateOTP}
            >
              Get OTP
            </button>
          </>
        )}
        {showOTP && (
          <>
            <div className="ui input fluid">
              <input
                type="number"
                max="999999"
                placeholder="Enter OTP"
                value={otp}
                onInput={({ target: { value } }: any) =>
                  value.toString().length === 11 ? false : setOTP(value)
                }
              />
            </div>
            <button
              className="ui primary button fluid"
              disabled={!otp}
              onClick={phoneNumberLogin}
            >
              Login
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default Login;
