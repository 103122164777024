import { useState } from "react";
import PersonIcon from "../../assets/images/person.svg";
import { logout } from "../../stores/user";
import "./index.scss";

function Account({ user }: { user: any }) {
  const [show, setShow] = useState(false);
  return (
    <div
      className={`Account ui right dropdown item${
        show ? " visible active" : ""
      }`}
      onClick={() => setShow(!show)}
    >
      <div className="username">
        <img src={PersonIcon} alt="" />
        <span className="name">
          {user.displayName ? user?.displayName : "Guest"}
        </span>
      </div>
      <div className={`menu transition${show ? " visible" : " hidden"}`}>
        <a className="item" href="/myaccount">
          <i className="boxes icon"></i>Orders
        </a>
        <a className="item" href="/myaccount">
          <i className="list ol icon"></i>Wishlist
        </a>
        <div className="item" onClick={() => logout()}>
          <i className="sign-out icon"></i>Logout
        </div>
      </div>
    </div>
  );
}

export default Account;
