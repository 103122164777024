import React, { Suspense } from "react";
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import { isLoggedIn } from "./stores/user";

// component
import Header from "./components/header";

//pages
const Home = React.lazy(() => import("./pages/home"));
const Detail = React.lazy(() => import("./pages/detail"));
const Journey = React.lazy(() => import("./pages/journey"));
const Products = React.lazy(() => import("./pages/products"));
const Experience = React.lazy(() => import("./pages/experiences"));
const NewArrivals = React.lazy(() => import("./pages/new-arrivals"));
const Stock = React.lazy(() => import("./pages/in-stock"));
const Sale = React.lazy(() => import("./pages/on-sale"));
const Store = React.lazy(() => import("./pages/stores"));
const Contact = React.lazy(() => import("./pages/contact"));
const Franchisee = React.lazy(() => import("./pages/franchisee"));
const MyAccount = React.lazy(() => import("./pages/my-account"));
const Orders = React.lazy(() => import("./pages/orders"));
const NoMatch = React.lazy(() => import("./pages/nomatch"));
const RedirectLogin = function () {
  return (
    <div className="RedirectLogin">
      <h1>Please login to view your account details</h1>
      <p>
        Don't have an account?{" "}
        <span
        className="link"
          onClick={() => document.dispatchEvent(new CustomEvent("open-login"))}
        >
          Sign up
        </span>
      </p>
    </div>
  );
};

const defaultState = {
  orders: [],
  user: null,
};
class App extends React.Component {
  state = { ...defaultState };
  render() {
    return (
      <div className="App">
        <Header items={this.state.orders} user={this.state.user} />
        <div className="body">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="newarrivals" element={<NewArrivals />} />
              <Route path="instock" element={<Stock />} />
              <Route path="onsale" element={<Sale />} />
              <Route
                path="detail/*"
                element={<Detail global={this} user={this.state.user} />}
              />
              <Route path="about" element={<Journey />} />
              <Route path="stores" element={<Store />} />
              <Route path="contact" element={<Contact />} />
              <Route path="franchisee" element={<Franchisee />} />
              <Route path="products/*" element={<Products />} />
              <Route path="experience/*" element={<Experience />} />
              {this.state.user && (
                <Route
                  path="myaccount"
                  element={<MyAccount user={this.state.user} />}
                />
              )}
              {!this.state.user && (
                <Route path="myaccount" element={<RedirectLogin />} />
              )}
              <Route
                path="/orders"
                element={
                  <Orders items={this.state.orders} user={this.state.user} />
                }
              />
              {/* 404 Page */}
              <Route path="*" element={<NoMatch />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    );
  }

  async componentDidMount() {
    document.addEventListener("update-cart", this.updateCart.bind(this));
    let user = await isLoggedIn();
    this.setState({ user });
    let ordersString = localStorage.getItem("orders");
    if (ordersString) {
      try {
        let orders = JSON.parse(ordersString);
        this.setState({ orders });
      } catch (error) {}
    }
  }

  updateCart({ detail }: any) {
    const idx = this.state.orders.findIndex(
      (order) => (order as any).id === detail.order.id
    );
    let orders: any = this.state.orders;
    if (detail.add) {
      detail.order.quantity = 1;

      if (idx !== -1) {
        (orders[idx] as any).quantity = (orders[idx] as any).quantity + 1;
      } else {
        orders = [...this.state.orders, detail.order];
      }
    } else {
      orders.splice(idx, 1);
    }

    this.setState(
      (prev) => ({ ...prev, orders }),
      () => {
        console.log(detail);
        localStorage.setItem("orders", JSON.stringify(this.state.orders));
      }
    );
  }
}

export default App;
