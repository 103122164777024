import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./theme/styles/index.css";
import "./index.scss";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = createRoot(document.getElementById("root")!);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
serviceWorkerRegistration.register();
