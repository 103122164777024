import React from "react";
import emptyCart from "../../assets/images/emptyCart.svg";
import "./index.scss";

// Icons

function calculateOrderSummary(items: ICartItem[]) {
  let parseFloatPro = (val: string) => (parseFloat(val) ? parseFloat(val) : 0);

  let shipping = 0,
    total = 0,
    grandTotal = 0;
  if (items && items.length > 0) {
    for (let i = 0; i < items.length; i++) {
      const element: any = items[i];
      total =
        total +
        parseFloatPro(element.salePrice) * parseFloatPro(element.quantity);
    }
    grandTotal = total;
  }
  return {
    shipping,
    total,
    grandTotal,
  };
}

const removeProduct = (item: ICartItem) => {
  document.dispatchEvent(
    new CustomEvent("update-cart", {
      detail: { order: { ...item } },
    })
  );
};

function Cart({ items }: { items: ICartItem[] }) {
  let x = calculateOrderSummary(items);
  return (
    <div
      className={`Cart${items && !items.length ? " empty" : ""}`}
      onClick={(e) => e.stopPropagation()}
    >
      {items && items.length && (
        <div>
          <div className="ui middle aligned divided list">
            {items.map((item, i) => {
              return (
                <div key={`Cart-list-${i}`} className="item">
                  <img
                    alt=""
                    className="ui tiny image"
                    src={item.images.split(",")[0]}
                  />
                  <div className="content">
                    <div className="header">{item.name}</div>
                    <div className="flex justify-between sub header">
                      <span>Size: {item.size}</span>
                      <span>Qty: {item.quantity}</span>
                    </div>
                    <div className="mb-1 sub header">
                      Price: {item.salePrice}
                    </div>
                    <div className="flex justify-between sub header">
                      <span>Save for later</span>
                      <i
                        className="trash icon"
                        onClick={() => removeProduct(item)}
                      ></i>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="wrapper">
            <h2 className="ui center aligned header">
              <div className="sub header">Subtotal</div>
              <strong>
                <i className="rupee sign icon"></i>
                {x.grandTotal}
              </strong>
            </h2>
            <button
              onClick={() => {
                window.location.replace("/orders");
              }}
              className="ui primary button fluid"
            >
              Checkout
            </button>
          </div>
        </div>
      )}
      {items && !items.length && (
        <div className="wrapper">
          <h2 className="ui center aligned header">Your Cart is Empty</h2>
          <img className="empty-cart" src={emptyCart} alt="" />
        </div>
      )}
    </div>
  );
}

export default Cart;
