import React, { useEffect, useState } from "react";
import "./index.scss";
import Logo from "../../assets/images/logo.png";

// Icons
import CartIcon from "../../assets/images/cart.svg";
import PersonIcon from "../../assets/images/person.svg";

// Components
import Cart from "../cart";
import Login from "../login";
import Account from "../account";

function Header({ items, user }: { items: IOrderItem[]; user: any }) {
  const [showCart, setShowCart] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    document.addEventListener("open-login", () => setShowLogin(true));
  });
  return (
    <nav className="Header">
      <div className="layer-1">
        <a href="/" className="brand">
          <img src={Logo} alt="store logo" />
          <span>Bow and Stern</span>
        </a>
        <div></div>
        <div className="desktop">
          <a href="/stores">Store Locator</a>
          <a href="/contact">Contact</a>
        </div>
        <div className="mobile">
          <a href="/stores">
            <i className="map marker alternate large inverted icon"></i>
          </a>
          <a href="/contact">
            <i className="warehouse large inverted icon"></i>
          </a>
        </div>
      </div>
      <div className="layer-2">
        <div>
          <a href="/newarrivals">New Arrivals</a>
          <a href="/instock">In Stock</a>
          <a href="/onsale">On Sale</a>
        </div>
        <div>
          {/* <a href='/newmembership'>Get Membership</a> */}
          {!user && (
            <a onClick={() => setShowLogin(!showLogin)}>
              <img src={PersonIcon} alt="" />
              <span className="name">Login</span>
            </a>
          )}
          {user && <Account user={user} />}
          <a
            onClick={() => {
              setShowCart(showCart ? false : true);
            }}
          >
            <div className="Label">
              {items.length > 0 && (
                <div className="ui teal label circular">{items.length}</div>
              )}
              <img alt="" src={CartIcon} />
              <span className="name">Cart</span>{" "}
            </div>
            {showCart && <Cart items={items} />}
          </a>
        </div>
      </div>
      <div></div>
      {showLogin && <Login close={() => setShowLogin(false)} />}
    </nav>
  );
}

export default Header;
